import React, { useState } from "react"
import PropTypes from "prop-types"
import getStripe from "../utils/getStripe"
const cardStyles = {
  marginLeft: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "flex-start",
  padding: "1rem",
  marginBottom: "1rem",
  boxShadow: "5px 5px 25px 0 rgba(46,61,73,.2)",
  backgroundColor: "#fff",
  borderRadius: "6px",
}
const buttonStyles = {
  display: "block",
  fontSize: "13px",
  textAlign: "center",
  color: "#000",
  padding: "12px",
  boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
  backgroundColor: "rgb(255, 178, 56)",
  borderRadius: "6px",
  letterSpacing: "1.5px",
}
const buttonDisabledStyles = {
  opacity: "0.5",
  cursor: "not-allowed",
}

const ProductCard = ({ product }) => {
  const [loading, setLoading] = useState(false)
  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    const price = product.id
    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: product.type === "one_time" ? "payment" : "subscription",
      lineItems: [{ price, quantity: 1 }],
      successUrl: `https://michaelolsen.tech/success/`,
      cancelUrl: `https://michaelolsen.tech/lab`,
    })
    if (error) {
      setLoading(false)
    }
  }
  return (
    <span style={cardStyles}>
      <form onSubmit={handleSubmit}>
        <fieldset style={{ border: "none" }}>
          <legend>
            <h4>{product.product.name}</h4>
          </legend>
        </fieldset>
        <h4>{`${product.currency} ${product.unit_amount / 100} ${
          product.type === "recurring" ? "/ month" : ""
        }`}</h4>
        <button
          disabled={loading}
          style={
            loading
              ? { ...buttonStyles, ...buttonDisabledStyles }
              : buttonStyles
          }
        >
          BUY ME
        </button>
      </form>
    </span>
  )
}

ProductCard.propTypes = {
  product: PropTypes.object,
}
export default ProductCard
