import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Products from "../components/products"

const Blog = ({ location }) => {
  return (
    <Layout location={location} title={"← Michael Olsen's Lab"}>
      <SEO title="Lab" />
      <section>
        <Products />
      </section>
    </Layout>
  )
}

Blog.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default Blog
