import React from "react"
import { graphql, StaticQuery } from "gatsby"
import ProductCard from "./productCard"

const products = () => {
  return (
    <StaticQuery
      query={graphql`
        query ProductPrices {
          prices: allStripePrice(
            filter: { active: { eq: true } }
            sort: { fields: [unit_amount] }
          ) {
            edges {
              node {
                id
                active
                currency
                unit_amount
                product {
                  id
                  name
                }
                type
              }
            }
          }
        }
      `}
      render={({ prices }) => {
        return (
          <div>
            {prices.edges.map(({ node: product }) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        )
      }}
    />
  )
}

export default products
